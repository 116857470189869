<template>
    <tr class="heighRow">
    <td class="text-right">
      <input
        v-if="edit"
        v-model="itemChoice.priority"
        placeholder="Thứ tự hiển thị"
        class="w-100 h-90 input text-right"
        style="padding: 5px"
      />
      <span class="w-100 text-right" v-else>{{ item.priority }}</span>
    </td>
    <td>
      <input
        v-if="edit"
        v-model="itemChoice.name"
        placeholder="Nhập tên tiêu chí"
        class="w-100 h-90 input"
        style="padding: 5px"
      />
      <span v-else>{{ item.name }}</span>
    </td>
    <td>
      <vue-autosuggest
        v-if="edit"
        v-model="itemChoice.cateName"
        :suggestions="filteredCateOptions"
        @selected="onSelectedCate"
        :limit="200"
        @input="onInputCateChange"
        :input-props="{
          class: 'autosuggest__input',
          placeholder: 'Chọn danh mục',
        }"
      >
        <div
          slot-scope="{ suggestion }"
          style="display: flex; align-items: center"
        >
          {{ suggestion.item.name }}
        </div></vue-autosuggest
      >
      <span v-else>{{ item.cateName }}</span>
    </td>
    <td>
      <b-form-textarea
        v-if="edit"
        v-model="itemChoice.description"
        placeholder="Nhập mô tả tiêu chí"
        class="w-100 h-90 input"
        style="padding: 5px"
        rows="5"
      />
      <span v-else>{{ genShortDescription(item.description) }}</span>
    </td>
    <td class="type">
      <b-form-select
        class="select-style"
        v-if="edit"
        size="sm"
        v-model="itemChoice.operatorType"
        :options="listType"
        value-field="id"
        text-field="name"
        :disabled="!edit"
      >
      </b-form-select>
      <b-form-select
        class="select-style"
        v-else
        size="sm"
        v-model="item.operatorType"
        :options="listType"
        value-field="id"
        text-field="name"
        :disabled="!edit"
      >
      </b-form-select>
    </td>
    <td class="text-right">
      <div class="row justify-content-center align-items-center" v-if="edit">
        <b-form-select
          size="sm"
          v-model="itemChoice.amountType"
          :options="listAmountType"
          value-field="id"
          text-field="name"
          :disabled="!edit"
          class="col-md-4 select-style"
        >
        </b-form-select>
        <input
          v-model="itemChoice.amount"
          placeholder="VD: 1.000.000"
          class="col-md-8 h-90 input text-right"
          style="padding: 5px"
          v-mask="mask"
        />
      </div>
      <div class="row justify-content-center align-items-center" v-else>
        <b-form-select
          size="sm"
          v-model="item.amountType"
          :options="listAmountType"
          required
          value-field="id"
          text-field="name"
          :disabled="!edit"
          class="col-md-4 select-style"
          v-if="edit"
        >
        </b-form-select>
        <div v-else style="padding: 5px">
          <span
            v-if="item.amountType === 1"
            v-text="'$'"
            class="font-weight-bold"
          ></span>
          <span v-else v-text="'%'" class="font-weight-bold"></span>
        </div>
        <span class="col-md-8 h-90 text-right" style="padding: 5px">{{
          formatMoney(item.amount)
        }}</span>
      </div>
    </td>
    <td>
      <template v-if="edit">
        <div class="d-flex justify-content-center">
          <v-icon class="mr-2 text-primary" small @click="updateItem()"
            >mdi-check</v-icon
          >
          <v-icon class="mr-2 text-danger" small @click="cancelEdit()"
            >mdi-close</v-icon
          >
        </div>
      </template>

      <template v-else>
        <div class="d-flex justify-content-center">
          <v-icon
            small
            class="mr-2 text-primary"
            @click="editItem()"
            v-b-tooltip
            title="Cập nhật"
            >mdi-pencil</v-icon
          >
          <v-icon
            small
            class="mr-2 text-danger"
            @click="showDeleteAlert"
            v-b-tooltip
            title="Xóa"
            >mdi-delete</v-icon
          >
        </div>
      </template>
    </td>
  </tr>
</template>

<script>
import Swal from 'sweetalert2';
import { currencyMask, unMaskPrice, removeAccents } from '@/utils/common';
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  props: ['item', 'listFeature', 'listCate'],
  data() {
    return {
      edit: false,
      itemChoice: {
        id: 0,
        priority: 1,
        name: '',
        operatorType: 1,
        amountType: 1,
        amount: 0,
        description: '',
        cateName: '',
        productComponentCategoryId: null,
      },
      listType: [
        {
          id: 1,
          name: '(+)',
        },
        {
          id: 2,
          name: '(-)',
        },
      ],
      mask: currencyMask,
      listAmountType: [
        {
          id: 1,
          name: '($)',
        },
        {
          id: 2,
          name: '(%)',
        },
      ],
      cateOptions: [
        {
          data: [],
        },
      ],
      filteredCateOptions: [],
    };
  },
  comments: {
    VueAutosuggest,
  },
  created() {
    this.getListCate();
  },
  updated() {
    this.checkAddRow();
  },
  methods: {
    editItem() {
      this.edit = true;
      this.itemChoice.id = this.item.id;
      this.itemChoice.priority = this.item.priority;
      this.itemChoice.name = this.item.name;
      this.itemChoice.amount = this.item.amount;
      this.itemChoice.operatorType = this.item.operatorType;
      this.itemChoice.amountType = this.item.amountType;
      this.itemChoice.description = this.item.description;
      this.itemChoice.cateName = this.item.cateName;
      this.itemChoice.productComponentCategoryId =
        this.item.productComponentCategoryId;
    },
    cancelEdit: function () {
      this.edit = false;
      if (this.item.amount === 0) {
        this.$emit('cancelAdd');
      }
      this.itemChoice.priority = 1;
      this.itemChoice.name = '';
      this.itemChoice.amount = 0;
      this.itemChoice.operatorType = 1;
      this.itemChoice.amountType = 1;
      this.itemChoice.description = '';
      this.itemChoice.cateName = '';
      this.itemChoice.productComponentCategoryId = null;
    },
    updateItem: function () {
      this.edit = false;
      if (
        this.item.amount == 0 &&
        this.item.name == '' &&
        this.item.operatorType == 1 &&
        this.item.amountType == 1 &&
        this.item.productComponentCategoryId == null
      ) {
        this.item.priority = this.itemChoice.priority;
        this.item.amount = unMaskPrice(this.itemChoice.amount);
        this.item.name = this.itemChoice.name;
        this.item.operatorType = this.itemChoice.operatorType;
        this.item.amountType = this.itemChoice.amountType;
        this.item.description = this.itemChoice.description;
        this.item.cateName = this.itemChoice.cateName;
        this.item.productComponentCategoryId =
          this.itemChoice.productComponentCategoryId;
        this.$emit('addItem', this.itemChoice);
      } else {
        this.$emit('updateItem', this.itemChoice);
      }
      this.itemChoice.operatorType = 1;
      this.itemChoice.amountType = 1;
      this.itemChoice.amount = 0;
      this.itemChoice.name = '';
      this.itemChoice.description = '';
      this.itemChoice.cateName = '';
      this.itemChoice.productComponentCategoryId = null;
    },
    showDeleteAlert: function () {
      Swal.fire({
        title: 'Xóa tiêu chí!',
        text: 'Bạn có chắc muốn xóa tiêu chí này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.$emit('deleteItem', this.item.id);
        }
      });
    },
    checkAddRow() {
      if (
        this.item.name == '' &&
        this.item.amount == 0 &&
        this.item.operatorType == 1 &&
        this.item.amountType == 1
      ) {
        this.edit = true;
      } else if (this.edit) {
        this.edit = true;
      } else {
        this.edit = false;
      }
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    genShortDescription(description) {
      let result = '';
      if (description.length > 100) {
        result = description.slice(0, 99) + '...';
      } else {
        result = description;
      }
      return result;
    },
    onSelectedCate(option) {
      this.itemChoice.productComponentCategoryId = option.item.id;
      this.itemChoice.cateName = option.item.name;
    },
    onInputCateChange(text) {
      this.itemChoice.cateName = text;

      const filteredData = this.cateOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCateOptions = [
        {
          data: filteredData,
        },
      ];
    },
    getListCate() {
      this.listCate.forEach((element) => {
        let name = '';
        if (element.level === 1) {
          name = '-' + element.name;
        } else if (element.level === 2) {
          name = '--' + element.name;
        } else if (element.level === 3) {
          name = '---' + element.name;
        } else if (element.level === 4) {
          name = '----' + element.name;
        } else if (element.level === 5) {
          name = '-----' + element.name;
        } else if (element.level === 6) {
          name = '------' + element.name;
        } else {
          name = element.name;
        }
        let item = {
          id: element.id,
          name: name,
        };
        this.cateOptions[0].data.push(item);
      });

      this.filteredCateOptions = [{ data: this.cateOptions[0].data }];
    },
  },
};
</script>


<style>
  .heighRow {
    height: 40px;
  }
  .textWidth {
    width: 70%;
  }
  .input {
    border: 1px solid #ecf0f3;
    border-radius: 5px;
  }
  th.pointType {
    width: 10%;
  }
  th.maxValue {
    width: 25%;
  }
  th.minValue,
  th.value {
    width: 25%;
  }
  .autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
